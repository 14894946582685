import React, { useState, useRef } from "react";
import "./resources/styles.css";
import Face1 from "../src/resources/images/Face1.JPG";
import Face2 from "../src/resources/images/Face2.JPG";
import Face3 from "../src/resources/images/Face3.JPG";
import Blue from "../src/resources/images/BLUE.JPG";
import zoomIn from "../src/resources/images/zoomIn.png";
import zoomOut from "../src/resources/images/zoomOut.png";
// import defaultresultimage from "../src/resources/images/Results-defaultimage.png";
import Processing from "../src/resources/images/processing.png";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import axios from "axios";
import AWS from "aws-sdk";
import { Slider } from "@mui/material";
import { v4 as uuidv4 } from "uuid";

const accessKeyId = "AKIAQUSWWFJDYZXQIZNB";
const secretAccessKey = "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";
const region = "us-east-2";
const bucketName = "orions3bucket";
const CORS = "https://cors-anywhere.herokuapp.com/";

const maxSliderValue = 1.0;
const minSliderValue = 0.01;
const noOfZoomStep = 23;
const sliderZoomStep = maxSliderValue / noOfZoomStep;
const ZoomInitialValue = (maxSliderValue + minSliderValue) / 2;



AWS.config.update({
  accessKeyId: accessKeyId,
  secretAccessKey: secretAccessKey,
  signatureVersion: "v4",
  region: region,
  params: { Bucket: bucketName },
});


const s3 = new AWS.S3({
  apiVersion: "2006-03-01",
  region: region,
  params: { Bucket: bucketName },
});

let s3Url;

function App() {
  const [uploadedImageURL, setUploadedImageURL] = useState("");
  const [croppedImageURL, setCroppedImageURL] = useState("");
  const [croppedImage, setCroppedImage] = useState(null);
  const [zoomedImage, setZoomedImage] = useState(null);
  const [zoomedWithSpaceImage, setZoomedWithSpaceImage] = useState(null);
  const [zoomedImageURL, setZoomedImageURL] = useState("");
  const [zoomedWithSpaceImageURL, setZoomedWithSpaceImageURL] = useState("");
  const [termsChecked, setTermsChecked] = useState(false);
  const [sharpnessChecked, setSharpnessChecked] = useState(false);
  const [zoomToFaceChecked, setZoomToFaceChecked] = useState(false);
  const [transformationImageURL, setTransformationImageURL] = useState("");
  const [zoomedTransformationImageURL, setZoomedTransformationImageURL] = useState("");
  const [zoomedWithSpaceTransformationImageURL, setZoomedWithSpaceTransformationImageURL] = useState("");
  const cropperRef = useRef(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [transformationResults, setTransformationResults] = useState(null);
  const [moderationResults, setModerationResults] = useState(null);
  const [showModerationResults, setShowModerationResults] = useState(false);
  const [showResults, setShowResults] = useState(false);
  const [uploadButtonDisabled, setUploadButtonDisabled] = useState(false);
  const [loading, setLoading] = useState(false);
  const cancelTokenSource = useRef(null);
  const [type, setType] = useState(""); 
  const [moderationReason, setModerationReason] = useState("");
  const [sharpnessValue, setSharpnessValue] = useState("");
  const [faceSharpnessValue, setFaceSharpnessValue] = useState("");
  const [transformationTime, setTransformationTime] = useState(null);
  const [moderationTime, setModerationTime] = useState(null);
  const [imageQualityTime, setImageQualityTime] = useState(null);
  const [faceDetectionTime, setFaceDetectionTime] = useState(null);
  const [zoomedTransformationTime, setZoomedTransformationTime] = useState(null);
  const [zoom, setZoom] = useState(ZoomInitialValue);
  const [isMoreThanOneFace, setIsMoreThanOneFace] = useState(false);


  function handleImageUpload(event) {
    if (!termsChecked) {
      return; // Do not proceed if the checkbox is not checked
    }

    setSharpnessValue(null);

    setShowResults(false);

    const file = event.target.files[0];
    const imageURL = URL.createObjectURL(file);
    setUploadedImageURL(imageURL);
    setCroppedImage(null); // Reset cropped image when a new image is uploaded
    setSelectedFile(file); // Store the selected file
    setZoomedImage(null);
    setZoomedImageURL(null);
    setZoomedWithSpaceImageURL(null)
    setZoomedTransformationImageURL(null);
    setZoomedWithSpaceTransformationImageURL(null);
    setFaceDetectionTime(null);
    setIsMoreThanOneFace(false);
  }

  function handleCrop() {

    console.log("inside handle crop");
    // console.log("get data: " + JSON.stringify(cropperRef.current.cropper.getData()));
    // console.log("get container data: " + JSON.stringify(cropperRef.current.cropper.getContainerData()));
    // console.log("get image data: " + JSON.stringify(cropperRef.current.cropper.getImageData()));
    // console.log("get canvas data: " + JSON.stringify(cropperRef.current.cropper.getCanvasData()));
    // console.log("get crop box data: " + JSON.stringify(cropperRef.current.cropper.getCropBoxData()));

    const canvas = getRoundedCanvas(
        cropperRef.current.cropper.getCroppedCanvas()
    );
    if (canvas) {
      canvas.toBlob((blob) => {
        const croppedFile = new File([blob], "cropped.png", {
          type: "image/png",
        });
        setCroppedImage(croppedFile);
        const croppedImageURL = URL.createObjectURL(croppedFile);
        setCroppedImageURL(croppedImageURL);
      }, "image/png");
    }
  }

  const getRoundedCanvas = (sourceCanvas) => {
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");
    const { width, height } = sourceCanvas;
    canvas.width = width;
    canvas.height = height;

    context.imageSmoothingEnabled = true;
    context.drawImage(sourceCanvas, 0, 0, width, height);
    context.globalCompositeOperation = "destination-in";
    context.beginPath();
    context.arc(
        width / 2,
        height / 2,
        Math.min(width, height) / 2,
        0,
        2 * Math.PI,
        true
    );
    context.fill();
    return canvas;
  };

  const generateUUID = () => {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      const r = (Math.random() * 16) | 0;
      const v = c === 'x' ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  };

  function addInterceptors() {
    axios.interceptors.request.use(function (config) {
      config.metadata = {startTime: new Date()}
      return config;
    }, function (error) {
      return Promise.reject(error);
    });

    axios.interceptors.response.use(function (response) {

      response.config.metadata.endTime = new Date()
      response.duration = response.config.metadata.endTime - response.config.metadata.startTime
      return response;
    }, function (error) {
      error.config.metadata.endTime = new Date();
      error.duration = error.config.metadata.endTime - error.config.metadata.startTime;
      return Promise.reject(error);
    });
  }

  async function handleSubmit() {
    if (!termsChecked) {
      return; // Do not proceed if the checkbox is not checked
    }

    console.log("get data: " + JSON.stringify(cropperRef.current.cropper.getData()));
    console.log("get container data: " + JSON.stringify(cropperRef.current.cropper.getContainerData()));
    console.log("get image data: " + JSON.stringify(cropperRef.current.cropper.getImageData()));
    console.log("get canvas data: " + JSON.stringify(cropperRef.current.cropper.getCanvasData()));
    console.log("get crop box data: " + JSON.stringify(cropperRef.current.cropper.getCropBoxData()));

    if (croppedImage) {
      setUploadButtonDisabled(true);
      const fileName = `cropped_${Date.now()}.png`;
      s3Url = `https://${bucketName}.s3.${region}.amazonaws.com/${fileName}`;
      const params = {
        Key: fileName,
        Body: croppedImage,
        ContentType: "image/png",
      };

      try {
        setLoading(true); // Show loading state
        console.log("Before S3 upload"); // Debugging statement
        const data = await s3.upload(params).promise();
        console.log("After S3 upload"); // Debugging statement
        const imageUrl = data.Location;

        const uuid = generateUUID();

        const requestBody = {
          image_url: imageUrl,
          image_id: uuid,
        };

        cancelTokenSource.current = axios.CancelToken.source(); // Create a new cancel token source

        try {
          console.log("Before API call"); // Debugging statement
          setShowResults(true);

          addInterceptors();

          const response = await axios.post(
              "https://mms-api.dev01.mars-phoenix.com/_internal/image-transformation/transformImage",
            requestBody,
            {
              'Origin': 'https://stage.demo-app.mmsretailgroup.com',
              cancelToken: cancelTokenSource.current.token
            }
          );

          setTransformationTime(response.duration/1000);
          console.log("\nTime taken for transformation: \n" + response.duration);

          console.log("API Request:", requestBody);
          console.log("API Response:", response.data);
          console.log("Status Code:", response.status);

          if (response.status !== 200) {
            setTransformationResults("we could not transform the images due to Network Security");
            return;
          }

          const resultImageURL = response.data.images[response.data.images.length - 1]; // Get the latest image URL
          console.log("The resultant image url is : "+resultImageURL);
          setTransformationImageURL(resultImageURL);

          // Call the second API with the new body
          const newRequestBody = {
            originalText: "",
            //imageUrl: resultImageURL // that in case we will take the tranformed image of the first API
            imageUrl: s3Url // Use the uploaded image URL from S3
          };

          try {
            const newResponse = await axios.post(
              "https://mms-api.dev01.mars-phoenix.com/_internal/content-moderation/moderate",
              newRequestBody,
              {
                'Origin': 'https://stage.demo-app.mmsretailgroup.com',
                cancelToken: cancelTokenSource.current.token
              }
            );

            setModerationTime(newResponse.duration/1000);
            setModerationReason(newResponse.data[0].message?.reason || "Valid");
            console.error(setModerationReason);

            // Update the state to display the moderation results
          setShowModerationResults(true);

          if (sharpnessChecked) {
            const imageQualityResponse = await axios.post(
                "https://mms-api.dev01.mars-phoenix.com/_internal/content-moderation/blur/test",
                newRequestBody,
                {
                  'Origin': 'https://stage.demo-app.mmsretailgroup.com',
                  cancelToken: cancelTokenSource.current.token
                }
            );

            console.log("Image quality response fetched: ");
            console.log(imageQualityResponse.data.sharpness);
            setImageQualityTime(imageQualityResponse.duration/1000);
            setSharpnessValue(imageQualityResponse.data.sharpness);
          }

          const faceDetectionResponse = await axios.post(
              "https://mms-api.dev01.mars-phoenix.com/_internal/content-moderation/face-detection",
                newRequestBody,
                {
                  'Origin': 'https://stage.demo-app.mmsretailgroup.com',
                  cancelToken: cancelTokenSource.current.token
                });

            console.log("Face detection response fetched: ");
            console.log(faceDetectionResponse.data);

            let faces = faceDetectionResponse.data.faces;

            setFaceDetectionTime(faceDetectionResponse.duration/1000);
            setFaceSharpnessValue(Math.min(...faces.map(o => o.sharpness)));

            const inputImage = document.getElementById("croppedImage");
            const outputImage = document.createElement('canvas');
            const context = outputImage.getContext('2d');

            outputImage.height = Math.max(...faces.map(o => o.height));

            if (faces.length > 1) {
              setIsMoreThanOneFace(true);
              outputImage.width = faces[0].width + faces[1].width;

              context.drawImage(inputImage, faces[0].leftStart, faces[0].topStart, faces[0].width, faces[0].height, 0, 0, faces[0].width, faces[0].height);
              context.drawImage(inputImage, faces[1].leftStart, faces[1].topStart, faces[1].width, faces[1].height, faces[0].width, 0, faces[1].width, faces[1].height);
            }
            else {
              outputImage.width = faces[0].width;
              context.drawImage(inputImage, -faces[0].leftStart, -faces[0].topStart);
            }

            context.globalCompositeOperation = "destination-in";
            context.beginPath();
            context.arc(
                outputImage.width / 2,
                outputImage.height / 2,
                Math.min(outputImage.width, outputImage.width) / 2,
                0,
                2 * Math.PI,
                true
            );
            context.fill();

            outputImage.toBlob((blob) => {
              const zoomedFile = new File([blob], "zoomed.png", {
                type: "image/png"
              });
              setZoomedImage(zoomedFile);
              uploadZoomedImageToS3(zoomedFile);
            }, "image/png");

            console.log("zoomed Image URL: " + zoomedImageURL);

            if (faceDetectionResponse.data.combinedFacesArea != null) {
              cropZoomedWithSpaceImage(faceDetectionResponse.data.combinedFacesArea);
            }

            setLoading(false);
            setShowResults(true);

          } catch (error) {
            console.error("Second API Call Error:", error);
          }
        } catch (error) {
          if (axios.isCancel(error)) {
            console.log("Request canceled:", error.message);
          } else {
            console.error("API Call Error:", error);
          }
        }
      } catch (error) {
        console.error("S3 Upload Error:", error);
        setLoading(false); // Hide loading state
      }
    }
  }

  async function uploadZoomedImageToS3(zoomedImageToUpload) {
    const fileName = `zoomed_${Date.now()}.png`;
    s3Url = `https://${bucketName}.s3.${region}.amazonaws.com/${fileName}`;
    const params = {
      Key: fileName,
      Body: zoomedImageToUpload,
      ContentType: "image/png"
    };

    console.log("Before S3 upload"); // Debugging statement
    const data = await s3.upload(params).promise();
    console.log("After S3 upload"); // Debugging statement
    console.log("uploaded zoomed image: " + JSON.stringify(data));

    setZoomedImageURL(s3Url);

    transformZoomedImage(s3Url);
  }

  async function transformZoomedImage(zoomedImageURL) {
    const uuid = generateUUID();

    const requestBody = {
      image_url: zoomedImageURL,
      image_id: uuid
    };

    cancelTokenSource.current = axios.CancelToken.source(); // Create a new cancel token source

    const response = await axios.post(
        "https://mms-api.dev01.mars-phoenix.com/_internal/image-transformation/transformImage",
        requestBody,
        {
          'Origin': 'https://stage.demo-app.mmsretailgroup.com',
          cancelToken: cancelTokenSource.current.token
        }
    );

    setZoomedTransformationTime(response.duration/1000);
    console.log("API Request:", requestBody);
    console.log("API Response:", response.data);
    console.log("Status Code:", response.status);

    if (response.status !== 200) {
      setTransformationResults("we could not transform the images due to Network Security");
      return;
    }

    const resultImageURL = response.data.images[response.data.images.length - 1]; // Get the latest image URL
    console.log("The resultant zoomed image url is : "+resultImageURL);
    setZoomedTransformationImageURL(resultImageURL);
  }

  function cropZoomedWithSpaceImage(imageInfo) {
    const inputImage = document.getElementById("croppedImage");
    const outputImage = document.createElement('canvas');
    const context = outputImage.getContext('2d');

    outputImage.height = imageInfo.height;
    outputImage.width = imageInfo.width;
    context.drawImage(inputImage, -imageInfo.leftStart, -imageInfo.topStart);

    context.globalCompositeOperation = "destination-in";
    context.beginPath();
    context.arc(
        outputImage.width / 2,
        outputImage.height / 2,
        Math.min(outputImage.width, outputImage.width) / 2,
        0,
        2 * Math.PI,
        true
    );
    context.fill();

    outputImage.toBlob((blob) => {
      const zoomedWithSpaceFile = new File([blob], "zoomedWithSpace.png", {
        type: "image/png"
      });
      setZoomedWithSpaceImage(zoomedWithSpaceFile);
      uploadZoomedWithSpaceImageToS3(zoomedWithSpaceFile);
    }, "image/png");

    console.log("zoomed image with space URL: " + zoomedWithSpaceImageURL);
  }

  async function uploadZoomedWithSpaceImageToS3(file) {
    const fileName = `zoomed_withspace_${Date.now()}.png`;
    const zoomedWithSpaces3Url = `https://${bucketName}.s3.${region}.amazonaws.com/${fileName}`;
    const params = {
      Key: fileName,
      Body: file,
      ContentType: "image/png"
    };

    console.log("Before S3 upload"); // Debugging statement
    const data = await s3.upload(params).promise();
    console.log("After S3 upload"); // Debugging statement
    console.log("uploaded zoomed image with space: " + JSON.stringify(data));

    setZoomedWithSpaceImageURL(zoomedWithSpaces3Url);
    transformZoomedImageWithSpace(zoomedWithSpaces3Url);
  }

  async function transformZoomedImageWithSpace(imageURL) {
    const uuid = generateUUID();

    const requestBody = {
      image_url: imageURL,
      image_id: uuid
    };

    cancelTokenSource.current = axios.CancelToken.source(); // Create a new cancel token source

    const response = await axios.post(
        "https://mms-api.dev01.mars-phoenix.com/_internal/image-transformation/transformImage",
        requestBody,
        {
          'Origin': 'https://stage.demo-app.mmsretailgroup.com',
          cancelToken: cancelTokenSource.current.token
        }
    );

    console.log("API Request:", requestBody);
    console.log("API Response:", response.data);
    console.log("Status Code:", response.status);

    if (response.status !== 200) {
      setTransformationResults("we could not transform the images due to Network Security");
      return;
    }

    const resultImageURL = response.data.images[response.data.images.length - 1]; // Get the latest image URL
    console.log("The resultant zoomed image url is : "+resultImageURL);
    setZoomedWithSpaceTransformationImageURL(resultImageURL);
  }

  function cancelRequest() {
    cancelTokenSource.current.cancel("Request canceled by the user");
    setLoading(false); // Set loading state to false when canceled
    setUploadButtonDisabled(false);
    setShowResults(false); // Set showResults to false when canceled
  }

    const [imageLoadError, setImageLoadError] = useState(false);
  
    const handleImageError = () => {
      if (!imageLoadError) {
        setImageLoadError(true);
      }
    };

  const handleZoom = (e, newValue) => {
    const zoomValue = Number(e.target.value);
    cropperRef.current.cropper.zoomTo(zoomValue);
    setZoom(zoomValue);
  };

  const zoomIncrease = () => {
    if (zoom >= maxSliderValue) {
      setZoom(maxSliderValue);
      return;
    }
    cropperRef.current.cropper.zoomTo(zoom + sliderZoomStep);
    setZoom(zoom + sliderZoomStep);
  };

  const zoomDecrease = () => {
    if (zoom <= minSliderValue) {
      setZoom(minSliderValue);
      return;
    }
    cropperRef.current.cropper.zoomTo(zoom - sliderZoomStep);
    setZoom(zoom - sliderZoomStep);
  };

  const touchmove = (e) => {
    if (e.detail.ratio >= maxSliderValue) {
      e.preventDefault();
      setZoom(maxSliderValue);
      return;
    } else if (e.detail.ratio <= minSliderValue) {
      e.preventDefault();
      setZoom(minSliderValue);
      return;
    }
    setZoom(e.detail.ratio);
  };


  return (
    <div className="App">
      <div className="columns-container">
        <div
          className="column"
          style={{
            fontFamily: "All Together Family Serif",
            color: "#5A1F06",
            borderRadius: "10px"
          }}
        >
          <h2>Choosing an Image</h2>
          <div style={{ display: "inline", justifyContent: "flex-start" }}>
            <img
              src={Face1}
              alt="Face 1"
              style={{ maxWidth: "100%", height: "100px" }}
            />
            <img
              src={Face2}
              alt="Face 2"
              style={{ maxWidth: "100%", height: "100px" }}
            />
            <img
              src={Face3}
              alt="Face 3"
              style={{ maxWidth: "100%", height: "100px" }}
            />
          </div>
          <ul>
            <li>Good, sharp center focused image with one or two faces</li>
            <li>Close ups and images with minimal backgrounds</li>
            <li>
              Avoid images with too much details as backgrounds will be removed
            </li>
            <li>No landscape pictures</li>
            <li>We recommend uploading a jpg that is between 5mb and 15mb</li>
            <li>Use a logo with no background</li>
            <li>Submitted photos must be your own private property</li>
          </ul>
          <div
            className="button-container"
            style={{ textAlign: "center", marginTop: "1rem" }}
          >
          <button
  className="upload-button"
  style={{
    backgroundColor: "#FDB32A",
    color: "#FFF",
    padding: "0.5rem 1rem",
    border: "none",
    borderRadius: "4px",
    opacity: !termsChecked ? 0.5 : 1,
    cursor: !termsChecked ? "not-allowed" : "pointer"
  }}
  onClick={() => document.getElementById("upload-input").click()}
  disabled={!termsChecked || loading}
>
  {loading ? (
    <img
      src={Processing}
      style={{ height: "20px" }}
    />
  ) : (
    "Upload Image"
  )}
</button>
            <input
              type="file"
              id="upload-input"
              onChange={handleImageUpload}
              style={{ display: "none" }}
            />
          </div>

          <div
            className="checkbox-container"
            style={{ display: "flex", alignItems: "center", marginTop: "1rem" }}
          >
            <input
              type="checkbox"
              id="terms"
              name="terms"
              value="terms"
              checked={termsChecked}
              onChange={(e) => setTermsChecked(e.target.checked)}
              style={{ marginRight: "0.5rem" }}
            />
            <label htmlFor="terms" style={{ marginLeft: "0.5rem" }}>
              I have read and agreed to the Terms & Conditions for uploading
              images.
            </label>
          </div>
        </div>

        {/* Middle Column */}
        <div
          className="column"
          style={{
            fontFamily: "All Together Family Serif",
            color: "#5A1F06",
            borderRadius: "10px"
          }}
        >
          <h2>Photo Processing</h2>
          <div className="cropper-container">
            {uploadedImageURL ? (
                <div>
                  <Cropper
                      src={uploadedImageURL}
                      ref={cropperRef}
                      crop={handleCrop}
                      aspectRatio={1}
                      cropBoxResizable={false}
                      zoomOnWheel={false}
                      zoomOnTouch={true}
                      style={{ height: 400, width: "100%" }}
                      viewMode={0}
                      zoomTo={ZoomInitialValue}
                      checkOrientation={false}
                      checkCrossOrigin
                      center={true}
                      rotatable={false}
                      zoomable
                      movable
                      scalable
                      toggleDragModeOnDblclick={false}
                      cropBoxMovable={false}
                      dragMode="move"
                      responsive={true}
                      minCropBoxWidth={300}
                      minCropBoxHeight={300}
                      background={false}
                      autoCrop
                      autoCropArea={0.9}
                      guides={false}
                      zoom={touchmove}
                  />
                  <div className="editImageWrapper">
                    <div className="controls">
                      <img
                          src={zoomOut}
                          onClick={zoomDecrease}
                          style={{
                            height: "auto",
                            width: "auto",
                          }}
                      />
                      <label
                          style={{
                            width: "100%",
                            maxWidth: "320px",
                            boxSizing: "border-box",
                            position: "relative",
                            top: "2px",
                            padding: "0 15px",
                          }}
                      >
                        <Slider
                            value={zoom}
                            min={minSliderValue}
                            max={maxSliderValue}
                            step={sliderZoomStep}
                            aria-labelledby="zoom"
                            onChange={handleZoom}
                            style={{
                              color: "#5a1f06",
                              padding: "15px 0",
                              height: "8px",
                              width: "100%",
                            }}
                        />
                      </label>
                      <img
                          src={zoomIn}
                          onClick={zoomIncrease}
                          style={{
                            height: "auto",
                            width: "auto",
                          }}
                      />
                    </div>
                  </div>
                </div>
            ) : (
              <img src={Blue} alt="Blue" />
            )}
          </div>

          <h3>Properties</h3>

          <div
              className="checkbox-container"
              style={{ display: "flex", alignItems: "center", marginTop: "1rem" }}
          >
            <input
                type="checkbox"
                id="sharpness"
                name="sharpness"
                value="sharpness"
                checked={sharpnessChecked}
                onChange={(e) => setSharpnessChecked(e.target.checked)}
                style={{ marginRight: "0.5rem" }}
            />
            <label htmlFor="sharpness" style={{ marginLeft: "0.5rem", marginTop: "1rem" }}>
              Check image quality
            </label>
          </div>

          <div
              className="checkbox-container"
              style={{ display: "flex", alignItems: "center", marginTop: "1rem" }}
          >
            <input
                type="checkbox"
                id="zoomToFace"
                name="zoomToFace"
                value="zoomToFace"
                checked={zoomToFaceChecked}
                onChange={(e) => setZoomToFaceChecked(e.target.checked)}
                style={{ marginRight: "0.5rem" }}
            />
            <label htmlFor="zoomToFace" style={{ marginLeft: "0.5rem", marginTop: "1rem" }}>
              Zoom to face
            </label>
          </div>

          {uploadedImageURL ? (
            <div>
              <br />
              <button
              className="proceed-button"
              style={{
                backgroundColor: "#FDB32A",
                color: "#FFF",
                padding: "0.5rem 1rem",
                border: "none",
                borderRadius: "4px",
                opacity: !termsChecked ? 0.5 : 1,
                cursor: !termsChecked ? "not-allowed" : "pointer"
              }}
              onClick={handleSubmit}
              disabled={!termsChecked || loading} // Disable button while loading
            >
              {loading ? (
                <img
                  src={Processing}
                  alt="Processing"
                  style={{ height: "20px" }}
                />
              ) : (
                "Proceed to Results"
              )}
            </button>
            {loading && (
            <div>
              <br />
              <p>Loading...</p>
              <button
                className="cancel-button"
                style={{
                  backgroundColor: "red",
                  color: "#FFF",
                  padding: "0.5rem 1rem",
                  border: "none",
                  borderRadius: "4px",
                  marginTop: "1rem"
                }}
                onClick={cancelRequest}
              >
                Cancel
              </button>
            </div>
          )}
            </div>
          ) : null}
        </div>

{/* Right Column */}
<div
  className="column"
  style={{
    fontFamily: "All Together Family Serif",
    color: "#5A1F06",
    borderRadius: "10px"
  }}
>
  <h2>Cropped Image</h2>
  <br />
  <div style={{ display: "inline", justifyContent: "flex-start" }}>
    {croppedImageURL ? (
      <img id="croppedImage" src={croppedImageURL} alt="Cropped" style={{
        maxWidth: "100%",
      }} />
    ) : (
      <img
        src={Processing}
        alt="Processing"
        style={{ maxWidth: "50%", height: "50px" }}
      />
    )}

<div>
{showResults && !loading ? (
  <>
    <h3>Transformed Image</h3> {transformationTime ? (<h4>({transformationTime} seconds)</h4>) : null}
    <div>
      {transformationImageURL && !imageLoadError ? (
        <img
          src={transformationImageURL}
          onError={handleImageError}
          style={{ height: '100px', width: '100px', borderRadius: '50%', border: '1px solid #000', filter: 'grayscale(100%)' }}
          alt="Transformation Image"
        />
      ) : (
        <div>
          {!imageLoadError ? (
            <div>Please wait while the image is getting transformed.</div>
          ) : (
            <>
              <img
                src={s3Url}
                alt="Default Image"
                style={{ height: '100px', width: 'auto', borderRadius: '50%', filter: 'grayscale(100%)' }}
              />
              <div>This is just a sample as we couldn't read the transformed image from the S3 Bucket.</div>
            </>
          )}
        </div>
      )}
    </div>
    <h3>Moderation Feedback</h3> {moderationTime ? (<h4>({moderationTime} seconds)</h4>) : null}
    <div>
        <div>
        {moderationReason ? (
          <p>Reason: {moderationReason ? moderationReason : "Need to be reviewed"}</p>
        ) : (
          <div>Please wait while the Score is being calculated</div>
        )}
        </div>
     
    </div>

    { sharpnessChecked && sharpnessValue ? (
    <>
    <h3>Image Quality</h3> {imageQualityTime ? (<h4>({imageQualityTime} seconds)</h4>) : null}
    <div>
      <div>
        {sharpnessValue ? (
            <p>Sharpness: {sharpnessValue ? sharpnessValue : "Value not retrieved yet"}</p>
        ) : (
            <div>Please wait while the sharpness is being calculated or try again</div>
        )}
      </div>

    </div>
    </>
      ) : null}
  </>
) : <img
    src={Processing}
    alt="Processing"
    style={{ maxWidth: "50%", height: "50px", marginTop: "1rem" }}
/>}

</div>


  </div>
</div>
        {zoomToFaceChecked ? (
        <>
        <div
            className="column"
            style={{
              fontFamily: "All Together Family Serif",
              color: "#5A1F06",
              borderRadius: "10px"
            }}
        >
          <h2>Zoomed Image (Without space)</h2> {faceDetectionTime ? (<h4>({faceDetectionTime} seconds)</h4>) : null}
          <br />
          <div style={{ display: "inline", justifyContent: "flex-start" }}>
            {zoomedImageURL ? (
                <img id="zoomedCroppedImage" src={zoomedImageURL} alt="Cropped" style={{
                  maxWidth: "100%",
                }} />
            ) : (
                <img
                    src={Processing}
                    alt="Processing"
                    style={{ maxWidth: "50%", height: "50px" }}
                />
            )}

            <div>
              {showResults && !loading ? (
                  <>
                    <h3>Transformed Image</h3> {zoomedTransformationTime ? (<h4>({zoomedTransformationTime} seconds)</h4>) : null}
                    <div>
                      {zoomedTransformationImageURL && !imageLoadError ? (
                          <img
                              src={zoomedTransformationImageURL}
                              onError={handleImageError}
                              style={{ height: '100px', width: '100px', borderRadius: '50%', border: '1px solid #000', filter: 'grayscale(100%)' }}
                              alt="Transformation Image"
                          />
                      ) : (
                          <div>
                            {!imageLoadError ? (
                                <div>Please wait while the image is getting transformed.</div>
                            ) : (
                                <>
                                  <img
                                      src={s3Url}
                                      alt="Default Image"
                                      style={{ height: '100px', width: 'auto', borderRadius: '50%', filter: 'grayscale(100%)' }}
                                  />
                                  <div>This is just a sample as we couldn't read the transformed image from the S3 Bucket.</div>
                                </>
                            )}
                          </div>
                      )}

                      { faceSharpnessValue ? (
                          <>
                            <h3>Image Quality</h3>
                            <div>
                              <div>
                                {faceSharpnessValue ? (
                                    <p>Sharpness: {faceSharpnessValue ? faceSharpnessValue : "Value not retrieved yet"}</p>
                                ) : (
                                    <div>Please wait while the face sharpness is being calculated or try again</div>
                                )}
                              </div>

                            </div>
                          </>
                      ) : null}
                    </div>
                  </>
              ) : <img
                  src={Processing}
                  alt="Processing"
                  style={{ maxWidth: "50%", height: "50px", marginTop: "1rem" }}
              />}

            </div>


          </div>
        </div>
        </> ) : null }

        {zoomToFaceChecked && isMoreThanOneFace ? (
            <>
              <div
                  className="column"
                  style={{
                    fontFamily: "All Together Family Serif",
                    color: "#5A1F06",
                    borderRadius: "10px"
                  }}
              >
                <h2>Zoomed Image</h2>
                <br />
                <div style={{ display: "inline", justifyContent: "flex-start" }}>
                  {zoomedWithSpaceImageURL ? (
                      <img id="zoomedWithSpaceCroppedImage" src={zoomedWithSpaceImageURL} alt="Cropped" style={{
                        maxWidth: "100%",
                      }} />
                  ) : (
                      <img
                          src={Processing}
                          alt="Processing"
                          style={{ maxWidth: "50%", height: "50px" }}
                      />
                  )}

                  <div>
                    {showResults && !loading ? (
                        <>
                          <h3>Transformed Image</h3>
                          <div>
                            {zoomedWithSpaceTransformationImageURL && !imageLoadError ? (
                                <img
                                    src={zoomedWithSpaceTransformationImageURL}
                                    onError={handleImageError}
                                    style={{ height: '100px', width: '100px', borderRadius: '50%', border: '1px solid #000', filter: 'grayscale(100%)' }}
                                    alt="Transformation Image"
                                />
                            ) : (
                                <div>
                                  {!imageLoadError ? (
                                      <div>Please wait while the image is getting transformed.</div>
                                  ) : (
                                      <>
                                        <img
                                            src={s3Url}
                                            alt="Default Image"
                                            style={{ height: '100px', width: 'auto', borderRadius: '50%', filter: 'grayscale(100%)' }}
                                        />
                                        <div>This is just a sample as we couldn't read the transformed image from the S3 Bucket.</div>
                                      </>
                                  )}
                                </div>
                            )}
                          </div>
                        </>
                    ) : <img
                        src={Processing}
                        alt="Processing"
                        style={{ maxWidth: "50%", height: "50px", marginTop: "1rem" }}
                    />}

                  </div>


                </div>
              </div>
            </> ) : null }
</div>
</div>
);
}
export default App;
