import { createRoot } from "react-dom/client";
import App from "./App";
// import Header from "./Header";

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

root.render(
  <>
    {/* <Header /> */}
    <App />
  </>
);
